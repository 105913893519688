import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

export default function CardImages({ items, onItemClick }) {
  const classes = useStyles();

  return (
    <GridContainer justify="center">
      {items.map(({ title, image, padding }, i) => (
        <GridItem
          key={`item-${i}`}
          className={`${classes.itemContainer} ${classes.hoverFade}`}
          xs={12}
          sm={6}
          md={3}
          onClick={onItemClick}
        >
          <div className={classes.content}>
            <div className={classes.imageContainer} style={{ padding }}>
              <img className={classes.image} src={image} />
            </div>
            <h3 className={classes.title}>{title}</h3>
          </div>
        </GridItem>
      ))}
    </GridContainer>
  );
}

CardImages.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    margin: "16px 0",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0",
      padding: "0 4px",
    },
    cursor: "pointer",
    transition: "all .2s cubic-bezier(0.4,0,0.2,1)",
  },
  content: {
    borderRadius: 6,
    boxShadow:
      "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
  },
  hoverFade: {
    "&:hover": {
      opacity: 0.65,
    },
  },
  imageContainer: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  title: {
    ...title,
    margin: 0,
    whiteSpace: "pre-line",
    textAlign: "center",
    marginTop: -20,
    paddingBottom: 20,
  },
}));
