import useI18nData from "./useI18nData";

const docs = [
  require("./solutions/food-delivery-app-development").default,
  require("./solutions/grocery-delivery-app-development").default,
  require("./solutions/on-demand-home-service-app-development").default,
  require("./solutions/ecommerce-app-development").default,
  require("./solutions/ecommerce-app-development-multi-vendor").default,
];

export default () => useI18nData(docs);
