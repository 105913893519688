import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle.js";
import ContactForm from "../ContactForm/ContactForm.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ContactThankYouDialog({
  open,
  onClose,
  onSubmitSuccessed,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      scroll="body"
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <ContactForm
        onSubmitSuccessed={() => {
          onClose();
          onSubmitSuccessed();
        }}
      />
    </Dialog>
  );
}

const useStyles = makeStyles({
  ...modalStyle,
  modal: {
    ...modalStyle.modal,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

ContactThankYouDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitSuccessed: PropTypes.func,
};
