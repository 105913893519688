import useI18nData from "./useI18nData";

export default () =>
  useI18nData({
    titles: [
      {
        size: "lg",
        text: "Mobile App Development",
        bold: true,
      },
      {
        text:
          "Choose a long-term partner who takes the whole mobile app development process off your shoulders",
      },
    ],
    backgroundAlpha: 0.5,
    sections: [
      {
        section: "features",
        title:
          "Build with us a beautiful, high-performance, and outstanding mobile app – on time, on budget",
        type: "border",
        itemType: "bigicon",
        items: [
          {
            title: "High Quality",
            icon: "favorite_border",
            description:
              "We have been developing mobile apps and websites for 10 years, your products will be created by our experienced designers and developers.",
          },
          {
            title: "High Development Speed",
            icon: "speed",
            description:
              "With the latest technologies of successful products in Silicon Valley, the development speed is more than 70% faster than traditional technologies.",
          },
          {
            title: "Agile Development",
            icon: "golf_course",
            description:
              "Agile development focuses on discovering requirements and developing solutions through users feedback, that avoid building unwanted functions and greatly reduce development costs.",
          },
          {
            title: "Low Development Cost",
            icon: "attach_money",
            description:
              "More than 50% of building a mobile app is duplicate work, such as login & registration, user management and server configurations, etc. To avoid wasting time, money and energy, we will reuse things apps have in common.",
          },
        ],
      },
      { section: "cards" },
      { section: "solutions" },
      { section: "max" },
    ],
    _i18n: {
      "zh-Hant": {
        titles: [
          {
            size: "lg",
            text: "手機應用開發",
            bold: true,
          },
          {
            text: "24/7 支援服務，讓您無後顧之憂！",
          },
        ],
        sections: [
          {
            section: "features",
            title: "與我們一起構建美觀、高性能且出色的手機應用程序",
            type: "border",
            itemType: "bigicon",
            items: [
              {
                title: "高品質",
                icon: "favorite_border",
                description:
                  "開發Apps和網站已有10年，由經驗豐富的設計師和編程團隊為您開發產品。",
              },
              {
                title: "高開發速度",
                icon: "speed",
                description:
                  "以矽谷成功產品所使用最新的React Native、Flutter、BaaS等等作為核心技術，開發速度比傳統技術快70%以上。",
              },
              {
                title: "敏捷式開發",
                icon: "golf_course",
                description:
                  "敏捷式開發(Agile Development)著重溝通及市場測試來改善產品，以免做出沒人使用的功能，大大降低開發成本。",
              },
              {
                title: "低開發成本",
                icon: "attach_money",
                description:
                  "已為常見App類型如電子商店、預約系統、會員系統等等開發了強大的模板引擎，比起由零開始開發一個App節省成本。",
              },
            ],
          },
          { section: "cards" },
          { section: "solutions" },
          { section: "max" },
        ],
      },
    },
  });
