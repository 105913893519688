export default {
  translation: {
    Home: "主頁",
    Services: "服務提供",
    Projects: "產品演示",
    Pricing: "價格",
    "Contact Us": "聯繫我們",
    Support: "支援服務",
    "Get a Quote": "獲取報價",
    "Mobile App Development": "手機應用開發",
    "Since our establishment, we have been delivering high-quality and sustainable software solutions for corporate purposes of worldwide businesses.":
      "自成立以來，我們一直為全球企業提供高質量和可持續的軟件開發方案。",
    "Latest Technology": "最新科技",
    "The latest technology verified by Silicon Valley greatly reduces the cost and time of development and maintenance.":
      "使用經矽谷驗證過的最新技術，大大降低開發維護的成本和時間。",
    "The best partner for Start-Up": "Start-Up 的最佳夥伴",
    "We have helped Start-Up to develop websites and mobile applications for many years, and have rich experience in solving common problems of Start-Up.":
      "我們多年來幫助 Start-Up 開發網站及手機應用，具有豐富經驗解決 Start-Up 常見的問題。",
    "WhatsApp Now": "WhatsApp 聯絡",
    "Email Now": "Email 聯絡",
    "Let's Develop Your Next Great App!": "開發您下一個偉大的App吧！",
    "Do you need a unique software solution for your company? We know how to help you!":
      "您是否需要獨特軟件解決方案？ 我們知道如何為您服務！",
    "Latest Projects": "產品演示",
    "In our portfolio, you can browse the latest products developed for our clients for different corporate purposes. Our qualified team of interface designers and software developers is always ready to create something unique for you.":
      "在我們的產品組合中，您可以瀏覽針對不同業務和功能所開發的最新產品。 我們優秀的界面設計師和軟件開發人員團隊隨時準備為您創建獨特的應用。",
    "Through the years we have partnered with great companies and entrepreneurs all over the world":
      "我們與全球業界頂級的服務提供者緊密合作",
    "Our Clients": "合作客戶",
    "Through The Years We Have Partnered With Great Companies And Entrepreneurs All Over The World":
      "我們的團隊擁有豐富的品牌經驗，客戶遍佈各行各業。",
    "UI Design": "UI 介面設計",
    "e-commerce": "商店App",
    "StartUp Special Offer": "Start-Up 特惠方案",
    "Enterprise Solution": "企業方案",
    Enterprise: "企業方案",
    "per month": "每月",
    "STARTING AT": "起",
    "Working hours: {{0}} up": "開發時數：{{0}} 小時以上",
    "Highly customized": "高度客製化",
    "Highly customized (Specific type and structure)":
      "高度客製化（特定類型及架構）",
    "Learn more": "進一步了解",
    Screenshot: "螢幕截圖",
    "Get Started": "開發諮詢",
    myKey: "{{a}}",
    Tel: "電話",
    Email: "電郵",
    "Your Name": "您的名稱",
    "Company Name": "公司名稱",
    "Email Address": "聯絡電郵",
    "Your Message": "簡單描述你的專案／工作內容",
    "Budget Range": "專案的預算",
    "Send Message": "傳送訊息",
    "Thank you, we will contact to you soon!": "謝謝，我們會盡快與您聯繫！",
    "Get in Touch": "開發諮詢",
    "Pick the best plan for you": "選擇最適合您的方案",
    "More than 50% of building a mobile app is duplicate work, such as login & registration, user management and server configurations, etc. To avoid wasting time, money and energy, we will reuse things apps have in common.":
      "我們已開發過數以百計的Apps，而大部份的開發項目有超過一半的工作是重複的，所以不需要從頭開始建立，例如會員系統、會員管理、即時通訊、推送信息、收費系統、訂閱系統等等，以避免浪費時間金錢。",
    "10 Years Of Experience": "10年開發經驗",
    "We have been worked on most of the common Apps such as E-commerce, Food Delivery, Live Chat and Social Networking etc and we find that they have a lot of functions in common, reusing them do help you launch your new businesses more quickly and at a fraction of the cost and energy. Let's boost your sales with these reliable，well tested and market-proven functions.":
      "日常生活中使用的App，例如電子商務、送外賣應用、直播應用、社交應用等等，我們都已開發過，當中有大量代碼能重複使用，節省數萬元和數千小時，能降低開發成本及更早推出市場，而且這些功能和代碼已經被市場驗證，都經歷過長時間和大量真實用戶測試，比起由零開發更安全可靠穩定，令你銷售量倍升。",
    "Make stable income with our top design.": "頂級設計，開創穩定收入。",
    "Professional Team": "頂尖開發團隊",
    "E-commerce, Multi Vendors, News, Ridesharing, Real Estate, Food Delivery, Social Media, Dating, Listing...":
      "商店、資訊發佈、Call車、房地產租售、外賣、社交、約會、服務平台等等",
    "We can help your business grow!": "全力協助及配合你的開發需求！",
    "Below {{0}}": "{{0}} 以下",
    "{{0}} and above": "{{0}} 以上",
    up: "起",
    Solutions: "解決方案",
  },
};
