import cloneDeepWith from "lodash.clonedeepwith";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default (docs) => {
  const { i18n } = useTranslation();
  return useMemo(
    () => cloneDeepWith(docs, (v, i, o) => o?._i18n?.[i18n.language]?.[i]),
    [docs, i18n.language]
  );
};
