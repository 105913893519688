import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Jarallax from "../../components/Jarallax/Jarallax";

export default function SectionBackground({
  id,
  className,
  backgroundImage,
  children,
  backgroundAlpha = 0,
}) {
  const classes = useStyles();

  const section = (
    <div
      id={id}
      className={classes.section}
      style={{
        backgroundColor: backgroundAlpha
          ? `rgba(0,0,0,${backgroundAlpha})`
          : undefined,
      }}
    >
      {children}
    </div>
  );

  return backgroundImage ? (
    <Jarallax
      className={`${classes.container}${className ? ` ${className}` : ""}`}
      image={backgroundImage}
      speed={0.2}
      type="scale-opacity"
    >
      {section}
    </Jarallax>
  ) : (
    section
  );
}

SectionBackground.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  backgroundAlpha: PropTypes.number,
};

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden",
  },
  section: {
    position: "relative",
    padding: "70px 0",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
