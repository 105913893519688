export default {
  section: "faq",
  items: [
    {
      title: "Can I Buy a Plan Now and Change it Later?",
      description:
        "Of course. You can buy a plan that best suits your needs now and you can change it anytime depending on your preferences. We will shift your remaining credits to the next plan you purchase.",
    },
    {
      title: "How Will You Help Me in the SetUp of My Platform?",
      description:
        "We offer 24/7 support with all our packages. When you purchase our product, we will do the basic setup to install the software for you. We also have guides and tutorials on how you can add or update content on the platform.",
    },
    {
      title: "Do I Need My Own Developer Account to Launch My App?",
      description:
        "Yes. Mobile App marketplaces charge a fee to launch an app. While Google Play Store charges a $25 one time fee for launching your app, Apple App Store charges $99 per year. As an app owner, you will have to pay this fee from your very own developer account.",
    },
    {
      title: "Who Will Pay For the Google Maps API Key?",
      description:
        "As an app owner, you will have to pay for all the third-party APIs and tools required for the functioning of your app",
    },
    {
      title: "Who Will Have Access to My Data?",
      description:
        "You are the only owner of your data while using solutions provided by ProApp. Our solutions are safe and secure - so, any breach of confidentiality is not at all possible. Also, if you ever want to cancel your account and stop using our services, your data will be exported and sent to you.",
    },
    {
      title: "Do I Need to Have Any Programming Knowledge to Update Content?",
      description:
        "Not at all. We have eliminated this barrier by letting you customize basic things in your app without having any programming knowledge. You can easily add images, content, or change fonts in your app just like you do while using social media apps. If you want to customize some other things that require coding skills, our team can help you with it.",
    },
    {
      title: "What Type of Products Can be Delivered Through this Platform?",
      description:
        "Our platform supports all types of products like alcohol, groceries, food, cannabis, flowers, jewelry, or anything under the sun that you plan to sell online.",
    },
    {
      title: "Can I Use My Own Domain?",
      description:
        "If you already have a domain purchased for your business, you can use it. Or, if you need any assistance in purchasing a domain, we can help you in the process.",
    },
    {
      title: "Is the Platform One-Store or Multi-Store?",
      description:
        "We have the solution up and running for both business models. It solely depends on what you require and how you plan your business. We can deliver to you both, right away.",
    },
    {
      title: "Can I Cancel My Subscription If I Want?",
      description:
        "Anytime! You can cancel your subscription whenever you wish. Also, you can continue using the platform after cancellation, until the end of your billing cycle.",
    },
  ],
};
