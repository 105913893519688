import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { container, title } from "../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GetStartedButton from "../../../components/GetStartedButton/GetStartedButton";
import GridItem from "../../../components/Grid/GridItem.js";
import AnimatedMockup from "../../../components/Mockup/AnimatedMockup";

export default function SectionBanner({
  image,
  animatedImages,
  title,
  content,
}) {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer alignItems="center">
          <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
            <GridItem className={classes.black} md={6}>
              <h3 className={classes.title}>{title}</h3>
              <h5>{content}</h5>
              <br />
              <GetStartedButton title="Contact Now" size="lg" />
            </GridItem>
          </Box>
          <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
            <GridItem md={6}>
              <AnimatedMockup
                animatedImages={animatedImages}
                image={image}
                macbook
              />
            </GridItem>
          </Box>
        </GridContainer>
      </div>
    </div>
  );
}

SectionBanner.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  animatedImages: PropTypes.array,
};

const useStyles = makeStyles(() => ({
  section: {
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container,
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    ...title,
    color: "#000",
  },
  black: {
    color: "#000",
  },
}));
