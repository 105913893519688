import { createBrowserHistory } from "history";
import i18n from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";

import HomePage from "views/HomePage/HomePage.js";
import ServicePage from "views/ServicePage/ServicePage";
import SolutionPage from "views/SolutionPage/SolutionPage";

import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";
import HeaderLinks from "./components/Header/HeaderLinks.js";
import PhotoSwipe from "./components/PhotoSwipe/PhotoSwipe";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";

import "assets/scss/material-kit-react.scss?v=1.9.0";
import "animate.css/animate.css";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: require("./i18n/en").default,
      "zh-Hant": require("./i18n/zh-Hant").default,
    },
    lng: "zh-Hant",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: "::",
    nsSeparator: ":::",
  });

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <div>
      <ScrollToTop />
      <Header
        brand="ProApp"
        rightLinks={(close) => <HeaderLinks close={close} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <Switch>
        <Route path="/services/:serviceId" component={ServicePage} />
        <Route path="/solutions/:solutionId" component={SolutionPage} />
        <Route path="/" component={HomePage} />
      </Switch>
      <Footer />
    </div>
    <PhotoSwipe />
  </Router>,
  document.getElementById("root")
);
