import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import GetStartedButton from "../../../../components/GetStartedButton/GetStartedButton";
import Jarallax from "../../../../components/Jarallax/Jarallax";

const imageHeight = 200;

export default function OverlayImages({ items, onItemClick }) {
  const classes = useStyles();

  const [flippedIndex, setFlippedIndex] = useState(null);

  return (
    <GridContainer justify="center">
      {items.map(({ title, image, description }, i) => (
        <GridItem
          key={`item-${i}`}
          className={`${classes.itemContainer}${
            description ? "" : ` ${classes.hoverFade}`
          }`}
          xs={12}
          sm={6}
          md={3}
          onClick={description ? undefined : onItemClick}
          onMouseEnter={() => setFlippedIndex(i)}
          onMouseLeave={() => setFlippedIndex(null)}
        >
          <ReactCardFlip
            cardStyles={{
              front: {
                transformStyle: "unset", //fix safari
                backgroundColor: "#000",
                boxShadow:
                  "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
              },
              back: {
                transformStyle: "unset", //fix safari
                backgroundColor: "#fff",
                boxShadow:
                  "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
              },
            }}
            infinite
            flipDirection="vertical"
            isFlipped={!!description && flippedIndex === i}
          >
            <Jarallax className={classes.front} image={image}>
              <div
                className={classes.content}
                onClick={() => setFlippedIndex(i)}
              >
                <h3 className={classes.title}>{title}</h3>
              </div>
            </Jarallax>

            {!description ? null : (
              <div className={classes.back}>
                <div>{description}</div>
                <GetStartedButton size="sm" />
              </div>
            )}
          </ReactCardFlip>
        </GridItem>
      ))}
    </GridContainer>
  );
}

OverlayImages.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    margin: "16px 0",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0",
      padding: "0 4px",
    },
    cursor: "pointer",
    transition: "all .2s cubic-bezier(0.4,0,0.2,1)",
  },
  front: {
    // remove borderRadius for Jarallax performance
    // borderRadius: 6,
    // overflow: "hidden",
  },
  hoverFade: {
    "&:hover": {
      opacity: 0.65,
    },
  },
  back: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",

    padding: 10,
    height: 200,
  },
  content: {
    height: imageHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 0.4)",
  },
  title: {
    ...title,
    color: "#fff",
    margin: 0,
    whiteSpace: "pre-line",
    textAlign: "center",
  },
}));
