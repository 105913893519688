import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle.js";
import Button from "../CustomButtons/Button.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ContactThankYouDialog({ open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="contact-thank-you-dialog-title"
      aria-describedby="contact-thank-you-dialog-description"
    >
      <DialogTitle
        id="contact-thank-you-dialog-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h4 className={classes.modalTitle}>ProApp</h4>
      </DialogTitle>
      <DialogContent
        id="contact-thank-you-dialog-description"
        className={classes.modalBody}
      >
        <p>{t("Thank you, we will contact to you soon!")}</p>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={onClose} color="danger" simple>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  ...modalStyle,
});

ContactThankYouDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
