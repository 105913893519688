import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import ContactDialog from "../../../../components/ContactDialog/ContactDialog.js";
import ContactThankYouDialog from "../../../../components/ContactThankYouDialog/ContactThankYouDialog.js";
import GetStartedButton from "../../../../components/GetStartedButton/GetStartedButton";
import SectionBackground from "../../../../components/SectionBackground/SectionBackground";
import CardImages from "./CardImages";
import OverlayImages from "./OverlayImages";

export default function SectionImages({
  backgroundImage,
  backgroundAlpha,
  title,
  subtitle,
  items,
  other,
  type,
}) {
  const classes = useStyles();

  const [
    isOpenContactThankYouDialog,
    setIsOpenContactThankYouDialog,
  ] = useState(false);

  const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

  return (
    <SectionBackground
      backgroundImage={backgroundImage}
      backgroundAlpha={backgroundAlpha}
    >
      <div className={classes.container}>
        <GridContainer className={classes.header}>
          <GridItem>
            <h2 className={classes.title}>{title}</h2>
          </GridItem>
          {!!subtitle && (
            <GridItem>
              <h5 className={classes.description}>{subtitle}</h5>
            </GridItem>
          )}
        </GridContainer>
        {type === "card" ? (
          <CardImages
            items={items}
            onItemClick={() => setIsOpenContactDialog(true)}
          />
        ) : (
          <OverlayImages
            items={items}
            onItemClick={() => setIsOpenContactDialog(true)}
          />
        )}
        {!!other && (
          <GridContainer className={classes.header}>
            <GridItem>
              <h2 className={`${classes.title} ${classes.bottom}`}>{other}</h2>
              <GetStartedButton title="Let's Discuss Your App" size="lg" />
            </GridItem>
          </GridContainer>
        )}
      </div>
      <ContactDialog
        open={isOpenContactDialog}
        onClose={() => setIsOpenContactDialog(false)}
        onSubmitSuccessed={() => setIsOpenContactThankYouDialog(true)}
      />
      <ContactThankYouDialog
        open={isOpenContactThankYouDialog}
        onClose={() => setIsOpenContactThankYouDialog(false)}
      />
    </SectionBackground>
  );
}

SectionImages.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundAlpha: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.array,
  other: PropTypes.string,
  type: PropTypes.string,
};

const useStyles = makeStyles({
  section: {
    padding: "70px 0",
  },
  container,
  header: {
    textAlign: "center",
  },
  description: {
    fontWeight: "bolder",
    marginBottom: 70,
  },
  space50: {
    height: "50px",
    display: "block",
  },
  title: {
    ...title,
    color: "#000",
  },
  bottom: {
    marginTop: 70,
  },
});
