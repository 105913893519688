import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import Button from "../../../../components/CustomButtons/Button.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import { open } from "../../../../components/PhotoSwipe/PhotoSwipe";
import useMax from "../../../../data/useMax";

export default function SectionMax() {
  const classes = useStyles();
  const { t } = useTranslation();

  const docs = useMax();

  return (
    <div id="mobile-app-development" className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          <GridItem>
            <h2 className={classes.title}>{t("Mobile App Development")}</h2>
            <h5 className={classes.description}>
              {t("Make stable income with our top design.")}
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem>
            <div
              className={`${classes.imageContainer} ${classes.textCenter}`}
              onClick={() => open(docs)}
            >
              <img
                src={require("./max.png")}
                alt="..."
                className={classes.image}
              />
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem className={classes.textCenter}>
            <Button
              style={{ backgroundColor: "#fff", color: "#000" }}
              round
              onClick={() => open(docs)}
            >
              {t("Screenshot")}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  section: {
    minHeight: "110vh",
    maxHeight: "1600px",
    padding: "70px 0",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000",
    overflow: "hidden",
  },
  container,
  title: {
    ...title,
    color: "#fff",
  },
  description: {
    color: "#fff",
  },
  imageContainer: {
    cursor: "pointer",
  },
  image: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-5%",
      width: "110%",
    },
  },
  textCenter: {
    textAlign: "center",
  },
}));
