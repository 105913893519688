export default {
  id: "ecommerce-app-development",
  menuTitle: "E-commerce App",
  titles: [
    { text: "Scale Your Business", bold: true },
    {
      size: "lg",
      text: "With an E-commerce Mobile App",
      bold: true,
    },
    {
      text:
        "Improve your customer’s engagement through our ready-made highly interactive eCommerce solutions.",
    },
  ],
  iphoneImage: require("./image/0.jpg"),
  macbookImage: require("./image/macbook.jpg"),
  backgroundImage: require("./image/background.jpg"),
  backgroundAlpha: 0.3,
  sections: [
    {
      section: "images",
      title: "We Build Profitable E-commerce Apps for",
      other: "Don't Belong to these?",
      items: [
        {
          title: "Grocery Store",
          image: require("./image/grocery-store.jpg"),
          description:
            "Planning to start a grocery delivery business like Instacart? Get a robust solution and deliver at a single tap of your customers.",
        },
        {
          title: "Jewelry Store",
          image: require("./image/jewelry-store.jpg"),
          description:
            "Launch an intuitive eCommerce mobile app for your Jewelry store that reflects your brand and business goals.",
        },
        {
          title: "Fashion Brands",
          image: require("./image/fashion-brands.jpg"),
          description:
            "We have helped a number of fashion retailers & brands in creating brand awareness and boosting sales using an eCommerce app.",
        },
        {
          title: "Shopping Mall",
          image: require("./image/shopping-mall.jpg"),
          description:
            "Build an interactive, powerful shopping mall app for your users and increase sales by many folds",
        },
        {
          title: "Furniture Store",
          image: require("./image/furniture-store.jpg"),
          description:
            "With the help of technologies like AR/VR and AI, we have helped a number of furniture stores in providing amazing UX to their users.",
        },
        {
          title: "Electronic Store",
          image: require("./image/electronic-store.jpg"),
          description:
            "Let us help you develop your electronic and computer store online so that you can sell effectively and boost revenues.",
        },
        {
          title: "Pharmacy Store",
          image: require("./image/pharmacy-store.jpg"),
          description:
            "Take your Pharmacy Store online with a powerful and interactive eCommerce store and boost your revenue",
        },
      ],
    },
    {
      section: "overview",
      image: require("./image/0.jpg"),
      animatedImages: [require("./image/5.jpg"), require("./image/17.jpg")],
      backgroundImage: require("./image/background-1.jpg"),
      backgroundAlpha: 0.3,
      title:
        "Take Your Business Online In No Time With Our Unique E-commerce Tech Suite",
      subtitle: "Our system is a comprehensive system of applied creativity.",
      items: [
        "Simple Registration Process",
        "Super Navigation",
        "Multiple Payment Options",
        "Product Filtering & Sorting",
        "Push Navigation",
        "Shipment Tracking",
      ],
    },
    {
      section: "overview",
      image: require("./image/admin.jpg"),
      animatedImages: [
        require("./image/admin-1.jpg"),
        require("./image/admin-2.jpg"),
      ],
      macbook: true,
      title: "Admin Dashboard",
      subtitle:
        "A complete web panel that will provide you the right set of tools to manage your eCommerce business with ease.",
      items: [
        "Content Management System",
        "Manage Users",
        "Manage Product Categories",
        "Track Sales",
        "Manage Promotions & Rewards",
        "Dashboard Management",
      ],
    },
    {
      section: "features",
      noContact: true,
      title: "Why Choose ProApp?",
      subtitle:
        "Renowned for building stunning e-commerce mobile apps & websites, we are favorite amongst global entrepreneurs looking to create unique marketplaces instantly.",
      items: [
        {
          title: "Dedicated Team",
          icon: "groups",
          description:
            "We provide a dedicated team for each project. We ensure that our clients don’t miss any update about the project.",
        },
        {
          title: "Start your Online Store",
          icon: "bolt",
          description:
            "We provide solutions, which let you start your online store in less than a week.",
        },
        {
          title: "Fully Secured Solution",
          icon: "lock",
          description:
            "We do simple, clean, and secure coding which protects your data and ensure Nobody sneaks in your sensitive data.",
        },
        {
          title: "24x7 Instant Support",
          icon: "support_agent",
          description:
            "Our team is always available to ensure your queries resolved in time.",
        },
      ],
    },
    /*
    {
      section: "features",
      noContact: true,
      title: "Impact of E-commerce Mobile App Solution",
      type: "border",
      items: [
        {
          title: "Increased Brand Recognition",
          icon: "business",
          description:
            "One of the chief reasons to choose mobile e-commerce app development is increased brand visibility. Also, it’s the best advantage of mobile commerce.",
        },
        {
          title: "Increase In Customer Retention",
          icon: "thumb_up",
          description:
            "Boost your sales and customer retention. Generate a new revenue stream for your e-commerce business, by launching a native mobile app.",
        },
        {
          title: "Increase In Yearly Sales",
          icon: "attach_money",
          description:
            "Customers who install an e-commerce app order 8x more times. Don’t miss out on a great opportunity to improve your bottom line.",
        },
        {
          title: "Increase in Customer Base",
          icon: "trending_up",
          description:
            "A constant brand value by maintaining an appealing layout and design on a mobile platform so that your customers may become habitual to it.",
        },
        {
          title: "Reduce Cart Abandonment Rates",
          icon: "shopping_cart",
          description:
            "If we check the past records, we will find that mobile apps have lower cart abandonment rates because of a simple checkout process.",
        },
        {
          title: "Higher Conversion Rate",
          icon: "replay",
          description:
            "Undoubtedly, mobile e-commerce applications assist in higher conversion rates. And, obviously, in the end, this is the only thing that matters as it contributes to revenue.",
        },
      ],
    },
    */
    {
      section: "discuss",
      title: "Improve your E-commerce user experience & Sales",
    },
    {
      section: "screenshots",
      title: "Screenshots of E-commerce App",
      videos: [
        require("./image/animate-0.mp4"),
        require("./image/animate-1.mp4"),
        require("./image/animate-4.mp4"),
        require("./image/animate-5.mp4"),
        require("./image/animate-6.mp4"),
        require("./image/animate-10.mp4"),
        require("./image/animate-8.mp4"),
        require("./image/animate-11.mp4"),
      ],
    },
  ],
  _i18n: {
    "zh-Hant": {
      menuTitle: "網店App",
    },
  },
};
