import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import YoutubeBackground from "react-youtube-background";

export default function Parallax({
  className,
  backgroundAlpha = 0,
  children,
  style,
  image,
  small,
}) {
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }

  const [transform, setTransform] = useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );

  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });

  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.small]: small,
    [className]: className !== undefined,
  });

  const [playing, setPlaying] = useState(false);

  const playRef = useRef();

  useEffect(() => {
    // auto play when switching back to Safari from background
    const timer = setInterval(() => playRef.current?.playVideo(), 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const content = (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage: playing
          ? "none"
          : `url("${image || require("assets/img/bg-movie.jpg")}")`,
      }}
    >
      {!!backgroundAlpha && (
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            top: 0,
            background: `rgba(0,0,0,${backgroundAlpha})`,
          }}
        />
      )}
      {children}
    </div>
  );

  return (
    <div
      style={{
        transform: transform,
      }}
    >
      {image ? (
        content
      ) : (
        <YoutubeBackground
          videoId="iJQHPRzJj20"
          onPlay={() => setPlaying(true)}
          onReady={(e) => {
            playRef.current = e.target;
          }}
        >
          {content}
        </YoutubeBackground>
      )}
    </div>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  backgroundAlpha: PropTypes.number,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};

const useStyles = makeStyles({
  parallax: {
    height: "90vh",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  },
  small: {
    height: "380px",
  },
});
