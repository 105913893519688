import { jarallax } from "jarallax";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

export default function Jarallax({
  className,
  image,
  children,
  speed = 1.1,
  type = "scroll",
}) {
  const ref = useRef();

  useEffect(() => {
    jarallax(ref.current, { speed, imgSrc: image, type });
    return () => {
      jarallax(ref.current, "destroy");
    };
  }, [image, speed, type]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}

Jarallax.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
  speed: PropTypes.number,
  type: PropTypes.string,
};
