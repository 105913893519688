import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

const types = {
  iphone: {
    gold: require("./apple-iphone12pro-gold-portrait.png"),
    graphite: require("./apple-iphone12pro-graphite-portrait.png"),
    pacificblue: require("./apple-iphone12pro-pacificblue-portrait.png"),
    silver: require("./apple-iphone12pro-silver-portrait.png"),
  },
  macbook: {
    gold: require("./apple-macbook-gold-front.png"),
  },
};

function Mockup(
  { className = "", image, type = "iphone", color = "gold", style },
  ref
) {
  const classes = useStyles();

  return (
    <img
      ref={ref}
      className={`${
        type === "iphone"
          ? classes.iphoneMockupImage
          : classes.macbookMockupImage
      } ${className}`}
      src={types[type][color]}
      style={{
        backgroundImage: `url('${image}')`,
        ...style,
      }}
    />
  );
}

export default forwardRef(Mockup);

Mockup.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

const useStyles = makeStyles({
  iphoneMockupImage: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "76.5%",
  },
  macbookMockupImage: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 48.3%",
    backgroundSize: "72%",
  },
});
