import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import React from "react";
import { title } from "../../../../assets/jss/material-kit-react.js";

export default function FeatureItem({ type, icon, title, description }) {
  const classes = useStyles();

  return type === "bigicon" ? (
    <div
      className={`${classes.container} ${classes.bigiconContainer} ${classes.textCenter}`}
    >
      <Icon className={classes.bigicon}>{icon}</Icon>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.description}>{description}</p>
    </div>
  ) : (
    <div className={classes.container}>
      <div style={{ float: "left", marginTop: 24, marginRight: 10 }}>
        <Icon className={classes.icon}>{icon}</Icon>
      </div>
      <div style={{ overflow: "hidden" }}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
}

FeatureItem.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const useStyles = makeStyles({
  container: {
    maxWidth: 360,
    margin: "0 auto",
  },
  bigiconContainer: {
    padding: "70px 0 30px",
  },
  topLeft: {
    borderLeft: "none",
    borderTop: "none",
  },
  top: {
    borderTop: "none",
  },
  topRight: {
    borderRight: "none",
    borderTop: "none",
  },
  bottomLeft: {
    borderLeft: "none",
    borderBottom: "none",
  },
  bottom: {
    borderBottom: "none",
  },
  bottomRight: {
    borderRight: "none",
    borderBottom: "none",
  },
  title: {
    ...title,
    color: "#000",
  },
  description: {
    color: "#000",
  },
  textCenter: {
    textAlign: "center",
  },
  bigicon: {
    color: "#000",
    fontSize: "3.85rem",
  },
  icon: {
    color: "#000",
    fontSize: "2.25rem",
  },
});
