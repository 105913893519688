import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../CustomButtons/Button.js";
import CustomDropdown from "../CustomDropdown/CustomDropdown.js";
import GetStartedButton from "../GetStartedButton/GetStartedButton";
import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import useServices from "../../data/useServices";
import useSolutions from "../../data/useSolutions";

const useStyles = makeStyles(styles);

const languageName = {
  en: "English",
  "zh-Hant": "中文",
};

export default function HeaderLinks({ close }) {
  const { t, i18n } = useTranslation();

  const solutions = useSolutions();

  //const services = useServices();

  const classes = useStyles();

  const renderButton = (id, title) => (
    <Button
      color="transparent"
      className={classes.navLink}
      href={`#${id}`}
      onClick={close}
    >
      {title}
    </Button>
  );

  // eslint-disable-next-line react/prop-types
  const renderLink = ({ href, title, onClick, to }) => {
    const props = {
      href,
      className: classes.dropdownLink,
      onClick: () => {
        onClick && onClick();
        close();
      },
      to,
      children: title,
    };
    return to ? <Link {...props} /> : <a {...props} />;
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          hoverColor="danger"
          noLiPadding
          buttonText={t("Solutions")}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={solutions.map(({ id, menuTitle }) =>
            renderLink({ to: `/solutions/${id}`, title: menuTitle })
          )}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          hoverColor="danger"
          noLiPadding
          buttonText={t("Services")}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          /*
          dropdownList={services.map(({ id, menuTitle }) =>
            renderLink({ to: `/services/${id}`, title: menuTitle })
          )}
          */
          dropdownList={[
            renderLink({
              href: "/#mobile-app-development",
              title: t("Mobile App Development"),
            }),
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        {renderButton("contact-us", t("Contact Us"))}
      </ListItem>

      <ListItem className={classes.listItem}>
        <GetStartedButton
          title={t("Get a Quote")}
          noIcon
          color="danger"
          round
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          hoverColor="danger"
          noLiPadding
          buttonText={languageName[i18n.language]}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            renderLink({
              onClick: () => i18n.changeLanguage("zh-Hant"),
              title: languageName["zh-Hant"],
            }),
            renderLink({
              onClick: () => i18n.changeLanguage("en"),
              title: languageName["en"],
            }),
          ]}
        />
      </ListItem>
    </List>
  );
}

HeaderLinks.propTypes = {
  close: PropTypes.func,
};
