import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Email from "@material-ui/icons/Email";
import WhatsApp from "@material-ui/icons/WhatsApp";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import ContactDialog from "../../../../components/ContactDialog/ContactDialog.js";
import ContactForm from "../../../../components/ContactForm/ContactForm.js";
import ContactThankYouDialog from "../../../../components/ContactThankYouDialog/ContactThankYouDialog.js";
import SectionBackground from "../../../../components/SectionBackground/SectionBackground";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function SectionFeatures() {
  const { t } = useTranslation();
  const classes = useStyles();

  const contacts = [
    {
      Logo: WhatsApp,
      title: "WhatsApp +852 9144 3907",
      uri: "https://wa.me/message/DSLGB5GUU2WFH1",
    },
    {
      Logo: Email,
      title: `${t("Email")} info@proapphk.com`,
      uri: "mailto:info@proapphk.com",
    },
  ];

  const [
    isOpenContactThankYouDialog,
    setIsOpenContactThankYouDialog,
  ] = useState(false);

  const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

  return (
    <SectionBackground
      id="contact-us"
      className={classes.section}
      backgroundImage={require("./hong-kong.jpg")}
      backgroundAlpha={0.65}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.title}>{t("Get in Touch")}</h2>
            <h5 className={classes.description}>
              {t("We can help your business grow!")}
            </h5>
            {contacts.map(({ Logo, title, uri }, i) => (
              <div key={`${i}`}>
                <div
                  style={{
                    float: "left",
                    marginTop: 24,
                    marginRight: 10,
                  }}
                >
                  <a
                    style={{ color: "#fff", cursor: "pointer" }}
                    href={uri}
                    //onClick={() => setIsOpenContactDialog(true)}
                  >
                    <Logo fontSize="large" />
                  </a>
                </div>

                <div
                  style={{
                    color: "#999",
                    overflow: "hidden",
                  }}
                >
                  <h4
                    className={classes.title}
                    style={{ margin: "1.75rem 0 0.875rem" }}
                  >
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      href={uri}
                      //onClick={() => setIsOpenContactDialog(true)}
                    >
                      {title}
                    </a>
                  </h4>
                  <div
                    style={{
                      color: "#999",
                      overflow: "hidden",
                      marginTop: 0,
                    }}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            ))}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <ContactForm
              onSubmitSuccessed={() => setIsOpenContactThankYouDialog(true)}
            />
          </GridItem>
        </GridContainer>
        <ContactDialog
          open={isOpenContactDialog}
          onClose={() => setIsOpenContactDialog(false)}
        />
        <ContactThankYouDialog
          open={isOpenContactThankYouDialog}
          onClose={() => setIsOpenContactThankYouDialog(false)}
        />
      </div>
    </SectionBackground>
  );
}

const useStyles = makeStyles({
  section: {
    // lag with jarallax
    // borderBottomLeftRadius: 6,
    // borderBottomRightRadius: 6,
  },
  container: {
    ...container,
  },
  title: {
    ...title,
    marginTop: 30,
    marginBottom: 25,
    color: "#FFF",
  },
  description: {
    color: "rgba(255, 255, 255, 0.76)",
  },
});
