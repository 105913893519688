import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Mockup from "./Mockup";

export default function MacbookIPhone({ iphoneImage, macbookImage }) {
  const classes = useStyles();

  return (
    <div className={classes.contianer}>
      <Mockup
        className={classes.macbookImage}
        image={macbookImage}
        type="macbook"
      />
      <Mockup className={classes.iphoneImage} image={iphoneImage} />
    </div>
  );
}

MacbookIPhone.propTypes = {
  macbookImage: PropTypes.string,
  iphoneImage: PropTypes.string,
};

const useStyles = makeStyles({
  contianer: {
    position: "relative",
  },
  macbookImage: {
    width: "100%",
  },
  iphoneImage: {
    position: "absolute",
    top: "13%",
    left: "46%",
    width: "38%",
  },
});
