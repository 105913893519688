import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import imagesStyles from "../../../../assets/jss/material-kit-react/imagesStyles.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import ShadowImage from "../../../../components/ShadowImage/ShadowImage.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import AnimatedMockup from "../../../../components/Mockup/AnimatedMockup";
import useSolutions from "../../../../data/useSolutions";

export default function SectionSolutions() {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const solutions = useSolutions();

  return (
    <div id="solutions" className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          <GridItem>
            <h2 className={classes.title}>{t("Solutions")}</h2>
            {
              //<h5 className={classes.description}>已為各行各業開發通用模板</h5>
            }
            <p className={classes.description}>
              {t(
                "E-commerce, Multi Vendors, News, Ridesharing, Real Estate, Food Delivery, Social Media, Dating, Listing..."
              )}
            </p>
          </GridItem>
        </GridContainer>
        <div className={classes.space50} />
        <GridContainer>
          {solutions.map(
            ({
              id,
              menuTitle: title,
              backgroundImage,
              backgroundAlpha,
              sections,
            }) => {
              const subtitle = "";
              const descriptions = null;
              const { image, animatedImages, macbook } = sections.find(
                ({ section, macbook }) => section === "overview" && !macbook
              );

              return (
                <GridItem key={title} xs={12} sm={6} md={4}>
                  <div className={classes.projectContainer}>
                    <ShadowImage
                      className={classes.imageContiner}
                      containerProps={{
                        onClick: () => history.push(`/solutions/${id}`),
                      }}
                      backgroundImage={backgroundImage}
                      backgroundAlpha={backgroundAlpha}
                    >
                      <div
                        style={{
                          width: "65%",
                          marginTop: -20,
                          marginBottom: -20,
                        }}
                      >
                        <AnimatedMockup
                          animatedImages={animatedImages}
                          image={image}
                          macbook={macbook}
                        />
                      </div>
                    </ShadowImage>

                    <div className={classes.projectDescriptionContainer}>
                      <h4 className={classes.projectTitle}>{title}</h4>
                      <h6 className={classes.projectSubtitle}>{subtitle}</h6>
                      <p className={classes.projectDescription}>
                        {descriptions?.map((description, i) => (
                          <span key={`${i}`}>
                            {description}
                            <br />
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                </GridItem>
              );
            }
          )}
        </GridContainer>
        <div className={classes.space50} />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  section: {
    marginTop: "3rem",
    padding: "70px 0",
  },
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  title,
  ...imagesStyles,
  projectContainer: {
    textAlign: "center",
  },
  imageContiner: {
    cursor: "pointer",
  },
  projectDescriptionContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    flex: "1 1 auto",
    padding: "0.9375rem 1.875rem",
  },
  projectTitle: {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
  },
  projectSubtitle: {
    color: "#999",
    textTransform: "none",
  },
  projectDescription: {
    marginTop: 20,
    color: "#999",
  },
  imageBlurShadow: {
    top: 12,
    width: "100%",
    filter: "blur(12px)",
    height: "100%",
    opacity: 0,
    zIndex: -1,
    position: "absolute",
    transform: "scale(0.94)",
    transition: "opacity .45s",
    backgroundSize: "cover",
  },
  textCenter: {
    textAlign: "center",
  },
});
