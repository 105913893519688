import useI18nData from "./useI18nData";

const docs = [
  { src: require("./img/max/onboarding.jpg"), w: 1125, h: 2436 },
  { src: require("./img/max/menu.jpg"), w: 375, h: 812 },
  { src: require("./img/max/home.jpg"), w: 375, h: 1024 },
  { src: require("./img/max/categories.jpg"), w: 375, h: 812 },
  { src: require("./img/max/profile.jpg"), w: 1242, h: 2688 },
  { src: require("./img/max/chat.jpg"), w: 1242, h: 2688 },
  { src: require("./img/max/cart.jpg"), w: 375, h: 1265 },
  { src: require("./img/max/search.jpg"), w: 375, h: 812 },
  { src: require("./img/max/deals.jpg"), w: 375, h: 812 },
  { src: require("./img/max/product-page.jpg"), w: 375, h: 812 },
  { src: require("./img/max/signin.jpg"), w: 375, h: 812 },
  { src: require("./img/max/signup.jpg"), w: 375, h: 812 },
  { src: require("./img/max/user-agreement.jpg"), w: 375, h: 812 },
  { src: require("./img/max/privacy.jpg"), w: 375, h: 812 },
  { src: require("./img/max/notification.jpg"), w: 375, h: 812 },
  { src: require("./img/max/settings.jpg"), w: 375, h: 812 },
];

export default () => useI18nData(docs);
