import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

export default function ShadowImage({
  className,
  containerProps,
  children,
  backgroundImage,
  backgroundAlpha,
  noHover,
}) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.imageContiner}${
        !noHover ? ` ${classes.hover}` : ""
      }${className ? ` ${className}` : ""}`}
      {...containerProps}
    >
      <div
        style={{
          backgroundColor: `rgba(0,0,0,${backgroundAlpha})`,
        }}
        className={classes.content}
      >
        {children}
      </div>
      <div
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          opacity: 1,
        }}
        className={classes.image}
      />
      <div
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          opacity: 1,
        }}
        className={classes.imageBlurShadow}
      />
    </div>
  );
}

ShadowImage.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.object,
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  backgroundAlpha: PropTypes.number,
  noHover: PropTypes.bool,
};

const useStyles = makeStyles({
  imageContiner: {
    padding: 0,
    margin: 0,
    zIndex: 1,
    position: "relative",
    transition: "all .2s cubic-bezier(0.4,0,0.2,1)",
  },
  content: {
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hover: {
    "&:hover": {
      opacity: 0.65,
    },
  },
  image: {
    top: 0,
    width: "100%",
    borderRadius: 6,
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    height: "100%",
    position: "absolute",
    zIndex: -1,
    backgroundSize: "cover",
  },
  imageBlurShadow: {
    top: 12,
    width: "100%",
    filter: "blur(12px)",
    height: "100%",
    opacity: 0,
    zIndex: -2,
    position: "absolute",
    transform: "scale(0.94)",
    transition: "opacity .45s",
    backgroundSize: "cover",
  },
});
