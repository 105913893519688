import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { container, title } from "../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

export default function SectionScreenshots({
  title,
  subtitle,
  images,
  videos,
}) {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.header}>
          <GridItem>
            <h2 className={classes.title}>{title}</h2>
          </GridItem>
          {!!subtitle && (
            <GridItem>
              <h5 className={classes.description}>{subtitle}</h5>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer justify="center">
          {images?.map((image, i) => (
            <GridItem key={`image-${i}`} xs={12} sm={6} md={3}>
              <img className={classes.image} src={image} />
            </GridItem>
          ))}
          {videos?.map((video, i) => (
            <GridItem key={`image-${i}`} xs={12} sm={6} md={3}>
              <div className={classes.itemContainer}>
                <video
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  autoPlay
                  loop
                  playsInline
                  muted
                >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}

SectionScreenshots.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  images: PropTypes.array,
  videos: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "70px 0",
  },
  container,
  header: {
    textAlign: "center",
  },
  description: {
    fontWeight: "bolder",
    marginBottom: 70,
  },
  title: {
    ...title,
    color: "#000",
  },
  image: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    "&:hover": {
      opacity: 0.65,
    },
  },
  itemContainer: {
    margin: "16px 0",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0",
      padding: "0 4px",
    },
    transition: "all .2s cubic-bezier(0.4,0,0.2,1)",
  },
}));
