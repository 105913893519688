import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import TypeIt from "typeit-react";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import YouTube from "react-youtube";
import { container, dangerColor } from "../../assets/jss/material-kit-react.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Parallax from "../../components/Parallax/Parallax.js";
import MacbookIPhone from "../../components/Mockup/MacbookIPhone";
import GetStartedButton from "../../components/GetStartedButton/GetStartedButton";
import SectionCards from "./Sections/SectionCards/SectionCards";
import SectionOverview from "./Sections/SectionOverview";
import SectionBanner from "./Sections/SectionBanner";
import SectionDiscuss from "./Sections/SectionDiscuss/SectionDiscuss";
import SectionContactUs from "./Sections/SectionContactUs/SectionContactUs";
import SectionFeatures from "./Sections/SectionFeatures/SectionFeatures";
import SectionClients from "./Sections/SectionClients/SectionClients";
import SectionFAQ from "./Sections/SectionFAQ";
import SectionImages from "./Sections/SectionImages/SectionImages";
import SectionSolutions from "./Sections/SectionSolutions/SectionSolutions";
import SectionScreenshots from "./Sections/SectionScreenshots";
import SectionMax from "./Sections/SectionMax/SectionMax";

export default function DynamicPage({
  backgroundImage,
  backgroundAlpha,
  titles,
  iphoneImage,
  macbookImage,
  videoId,
  sections,
}) {
  const classes = useStyles();

  const headerScreenshot = (
    <GridItem sm={12} md={5}>
      {videoId ? (
        <YouTube
          videoId={videoId}
          className={classes.youtube}
          opts={{
            height: "250",
          }}
        />
      ) : iphoneImage ? (
        <MacbookIPhone
          macbookImage={macbookImage}
          iphoneImage={iphoneImage}
          type="macbook"
        />
      ) : null}
    </GridItem>
  );

  return (
    <>
      <Parallax image={backgroundImage} backgroundAlpha={backgroundAlpha}>
        <div className={classes.container}>
          <GridContainer alignItems="center">
            <GridItem sm={12} md={7}>
              <div className={classes.brand}>
                {titles.map(({ size, text, bold, typeIt }, i) => {
                  const child = typeIt ? (
                    <div className={classes.typeit}>
                      <TypeIt
                        options={{ loop: true }}
                        getBeforeInit={(instance) => {
                          for (let t of text)
                            instance.type(t).pause(3000).delete(t.length);
                          return instance;
                        }}
                      />
                    </div>
                  ) : (
                    text
                  );

                  return size === "lg" ? (
                    <h1
                      key={`title-${i}`}
                      className={`${classes.title}${
                        bold ? ` ${classes.bold}` : ""
                      }`}
                    >
                      {child}
                    </h1>
                  ) : size === "md" ? (
                    <h2
                      key={`title-${i}`}
                      className={`${classes.mdTitle}${
                        bold ? ` ${classes.bold}` : ""
                      }`}
                    >
                      {child}
                    </h2>
                  ) : (
                    <h3
                      key={`title-${i}`}
                      className={`${classes.subtitle}${
                        bold ? ` ${classes.bold}` : ""
                      }`}
                    >
                      {child}
                    </h3>
                  );
                })}
                <br />
                <GetStartedButton size="lg" />
              </div>
            </GridItem>
            <Hidden smDown>{headerScreenshot}</Hidden>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <Hidden mdUp>
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>{headerScreenshot}</GridContainer>
            </div>
          </div>
        </Hidden>

        {sections.map(({ section, ...rest }, i) => {
          switch (section) {
            case "features":
              return <SectionFeatures key={`section-${i}`} {...rest} />;
            case "overview":
              return (
                <SectionOverview
                  key={`section-${i}`}
                  reverse={i % 2 === 0}
                  {...rest}
                />
              );
            case "banner":
              return <SectionBanner key={`section-${i}`} {...rest} />;
            case "discuss":
              return <SectionDiscuss key={`section-${i}`} {...rest} />;
            case "faq":
              return <SectionFAQ key={`section-${i}`} {...rest} />;
            case "images":
              return <SectionImages key={`section-${i}`} {...rest} />;
            case "screenshots":
              return <SectionScreenshots key={`section-${i}`} {...rest} />;
            case "cards":
              return <SectionCards key={`section-${i}`} {...rest} />;
            case "solutions":
              return <SectionSolutions key={`section-${i}`} {...rest} />;
            case "max":
              return <SectionMax key={`section-${i}`} {...rest} />;
            default:
              break;
          }
        })}
        <SectionClients />
        <SectionContactUs />
      </div>
    </>
  );
}

DynamicPage.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundAlpha: PropTypes.number,
  titles: PropTypes.array,
  iphoneImage: PropTypes.string,
  macbookImage: PropTypes.string,
  videoId: PropTypes.string,
  sections: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "70px 0 0 0",
  },
  container,
  typeit: {
    color: dangerColor,
  },
  youtube: {
    width: "100%",
    boxShadow:
      "0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: "3.6rem",
    position: "relative",
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2rem",
    },
  },
  mdTitle: {
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    fontSize: "2.2rem",
    position: "relative",
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2rem",
    },
  },
  bold: {
    fontWeight: "600",
  },
  subtitle: {
    fontSize: "1.313rem",
    margin: "10px 0 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: -30,
      marginLeft: 10,
      marginRight: 10,
    },
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
}));
