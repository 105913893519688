import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import useOnScreen from "../../utils/useOnScreen";
import Mockup from "./Mockup";

export default function AnimatedMockup({ macbook, image, animatedImages }) {
  const classes = useStyles();

  const centerImageRef = useRef();
  const isCenterImageVisible = useOnScreen(centerImageRef);

  let imageClass;
  let animationName = "";
  let animatedImageClasses;

  if (animatedImages.length === 2) {
    imageClass = macbook
      ? classes.macbookCenterimage
      : classes.iphoneCenterimage;
    animationName = " animate__animated animate__fadeInUp";
    animatedImageClasses = macbook
      ? [classes.macbookLeftImage, classes.macbookRightImage]
      : [classes.iphoneLeftImage, classes.iphoneRightImage];
  } else {
    imageClass = macbook
      ? classes.macbookCenterimage
      : classes.iphoneCenterimage;
    animatedImageClasses = [
      classes.floating0,
      classes.floating1,
      classes.floating2,
    ];
  }

  return (
    <div className={classes.container}>
      <Mockup
        ref={centerImageRef}
        type={macbook ? "macbook" : "iphone"}
        className={imageClass}
        image={image}
      />
      {animatedImages.map((animatedImage, i) => (
        <img
          key={`image-${i}`}
          className={`${animatedImageClasses[i]}${
            isCenterImageVisible ? animationName : ""
          }`}
          style={{
            opacity: isCenterImageVisible ? 1 : 0,
          }}
          src={animatedImage}
        />
      ))}
    </div>
  );
}

AnimatedMockup.propTypes = {
  image: PropTypes.string,
  animatedImages: PropTypes.array,
  macbook: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -10,
    marginRight: -10,
  },
  iphoneLeftImage: {
    position: "absolute",
    top: "42%",
    left: "5%",
    animationDuration: "1s",
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    width: "30%",
  },
  iphoneCenterimage: {
    width: "60%",
  },
  iphoneRightImage: {
    position: "absolute",
    top: "0%",
    left: "65%",
    animationDuration: "1s",
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    width: "30%",
  },
  macbookLeftImage: {
    position: "absolute",
    top: "55%",
    left: "0%",
    animationDuration: "1s",
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    width: "45%",
  },
  macbookCenterimage: {
    width: "100%",
  },
  macbookRightImage: {
    position: "absolute",
    top: "18%",
    left: "55%",
    animationDuration: "1s",
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    width: "45%",
  },
  floating0: {
    position: "absolute",
    top: "52%",
    left: "0%",
    width: "28%",
    animation: `$floating0 14s ${theme.transitions.easing.easeInOut} infinite`,
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    borderRadius: 5,
  },
  floating1: {
    position: "absolute",
    top: "15%",
    left: "36%",
    width: "22%",
    animation: `$floating1 18s ${theme.transitions.easing.easeInOut} infinite`,
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    borderRadius: 5,
  },
  floating2: {
    position: "absolute",
    top: "55%",
    left: "70%",
    width: "12%",
    animation: `$floating0 16s ${theme.transitions.easing.easeInOut} infinite`,
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
    borderRadius: 5,
  },
  "@keyframes floating0": {
    "0%": {
      transform: "translate(50px)",
    },
    "65%": {
      transform: "translate(0)",
    },
    "100%": {
      transform: "translate(50px)",
    },
  },
  "@keyframes floating1": {
    "0%": {
      transform: "translate(0)",
    },
    "65%": {
      transform: "translate(50px)",
    },
    "100%": {
      transform: "translate(0)",
    },
  },
}));
