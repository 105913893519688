import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { container, title } from "../../../assets/jss/material-kit-react.js";
import GetStartedButton from "../../../components/GetStartedButton/GetStartedButton";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import AnimatedMockup from "../../../components/Mockup/AnimatedMockup";
import SectionBackground from "../../../components/SectionBackground/SectionBackground";

export default function SectionOverview({
  backgroundImage,
  backgroundAlpha,
  headerTitle,
  headerSubtitle,
  image,
  animatedImages,
  title,
  subtitle,
  items,
  macbook,
  reverse,
}) {
  const classes = useStyles();

  return (
    <SectionBackground
      backgroundImage={backgroundImage}
      backgroundAlpha={backgroundAlpha}
    >
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          {!!headerTitle && (
            <GridItem>
              <h2
                className={`${classes.title} ${
                  backgroundImage ? classes.white : classes.black
                }`}
              >
                {headerTitle}
              </h2>
            </GridItem>
          )}
          {!!headerSubtitle && (
            <GridItem>
              <h5
                className={`${classes.headerSubtitle} ${
                  backgroundImage ? classes.white : classes.black
                }`}
              >
                {headerSubtitle}
              </h5>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer alignItems="center">
          <Box clone order={{ xs: 1, sm: 1, md: reverse ? 2 : 1 }}>
            <GridItem md={6}>
              <AnimatedMockup
                animatedImages={animatedImages}
                image={image}
                macbook={macbook}
              />
            </GridItem>
          </Box>
          <Box clone order={{ xs: 1, sm: 1, md: reverse ? 1 : 2 }}>
            <GridItem
              className={backgroundImage ? classes.white : classes.black}
              md={6}
            >
              <h2
                className={`${classes.title} ${
                  backgroundImage ? classes.white : classes.black
                }`}
              >
                {title}
              </h2>
              <h5 className={classes.subtitle}>{subtitle}</h5>
              <Grid item container>
                {items.map((item, i) => (
                  <Grid key={`item-${i}`} item sm={6} className={classes.item}>
                    <Icon className={classes.icon}>check_circle_outlined</Icon>
                    <h5 className={classes.itemTitle}>{item}</h5>
                  </Grid>
                ))}
              </Grid>
              <br />
              <GetStartedButton size="lg" />
            </GridItem>
          </Box>
        </GridContainer>
      </div>
    </SectionBackground>
  );
}

SectionOverview.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundAlpha: PropTypes.number,
  headerTitle: PropTypes.string,
  headerSubtitle: PropTypes.string,
  image: PropTypes.string,
  animatedImages: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.array,
  macbook: PropTypes.bool,
  reverse: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  container,
  title: {
    ...title,
    whiteSpace: "pre-line",
  },
  item: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  itemTitle: {
    fontWeight: "bold",
    marginLeft: 10,
  },
  icon: {
    fontSize: "2rem",
  },
  white: {
    color: "#fff",
  },
  black: {
    color: "#000",
  },
  textCenter: {
    textAlign: "center",
  },
  headerSubtitle: {
    marginBottom: 70,
  },
}));
