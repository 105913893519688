export default {
  id: "food-delivery-app-development",
  menuTitle: "Food Delivery App",
  titles: [
    { size: "lg", text: "On-Demand", bold: true },
    { size: "lg", text: "Food Delivery App" },
    {
      text: "Solution for Restaurants & Food Aggregators",
      bold: true,
    },
    {
      text:
        "It’s high time when you should use your own on-demand food delivery application and give your customers the convenience of ordering food on board.",
    },
  ],
  iphoneImage: require("./image/iphone.jpg"),
  macbookImage: require("./image/macbook.jpg"),
  backgroundImage: require("./image/background.jpg"),
  backgroundAlpha: 0.4,
  sections: [
    {
      section: "features",
      title: "Delivery Service App Solution",
      subtitle:
        "Expand Your Restaurant Business With A Food Delivery Service App Solution",
      type: "border",
      itemType: "bigicon",
      items: [
        {
          title: "Real-Time Tracking",
          icon: "pin_drop",
          description:
            "Track your drivers in real-time, analyze their performance, and improve your business processes to streamline deliveries using a robust admin panel.",
        },
        {
          title: "Multiple Payment Options",
          icon: "credit_card",
          description:
            "Less time spent in the ordering process means better user experience. Let your app users pay easily using multiple payment options so as to improve their overall app experience.",
        },
        {
          title: "Reports and Analytics",
          icon: "assessment",
          description:
            "Use the app's in-built advanced analytics to see and analyze how your business is performing. Identify problems and make informed decisions to grow your sales.",
        },
        {
          title: "Manage Orders",
          icon: "list_alt",
          description:
            "Avoid messy ordering processes and long queues with our Pizza Delivery service app. Manage everything while sitting at your home with a robust Admin Panel.",
        },
        {
          title: "Better ROI",
          icon: "attach_money",
          description:
            "Make effective strategies by using features like Push Notifications and provide real-time updates to your customers about new deals to increase your ROI.",
        },
        {
          title: "Promote Your Business Effectively",
          icon: "timeline",
          description:
            "Provide your customer loyalty bonuses, introduce referrals and do a lot more to promote your business using all-in-one robust admin panel.",
        },
      ],
    },
    {
      section: "overview",
      image: require("./image/customer.jpg"),
      animatedImages: [
        require("./image/customer-1.jpg"),
        require("./image/customer-2.jpg"),
      ],
      backgroundImage: require("./image/background-1.jpg"),
      title: "CUSTOMER APP",
      subtitle:
        "Let your customers order hassle-free from your App & Website and provide them an omnichannel experience using amazing features.",
      items: [
        "Easy App Onboarding",
        "Hassle-Free Ordering",
        "User-Friendly Interface",
        "Multiple Payment Options",
        "Order Status",
        "Order Tracking",
      ],
    },
    {
      section: "overview",
      image: require("./image/admin.jpg"),
      animatedImages: [
        require("./image/admin-1.jpg"),
        require("./image/admin-2.jpg"),
      ],
      macbook: true,
      title: "ADMIN PANEL",
      subtitle:
        "Manage and track everything using a smart dashboard from the comfort of your home. Analyze app performance and take action.",
      items: [
        "Business Control",
        "Menu Management",
        "Customer Management",
        "Order Management",
        "Payments & Commissions",
        "Check Reports & Analytics",
      ],
    },
    {
      section: "overview",
      image: require("./image/driver.jpg"),
      animatedImages: [
        require("./image/driver-1.jpg"),
        require("./image/driver-2.jpg"),
      ],
      backgroundImage: require("./image/background-2.jpg"),
      title: "DRIVER APP",
      subtitle:
        "Let your drivers navigate fast from one location to another using the Route Optimization feature and ensure on-time deliveries.",
      items: [
        "Access to Optimal Routes",
        "Availability of Information",
        "Set Status",
        "Get Order Details",
        "Payments & Performance",
        "Track Pickup Locations",
      ],
    },
    {
      section: "banner",
      image: require("./image/banner.jpg"),
      animatedImages: [
        require("./image/banner-1.jpg"),
        require("./image/banner-2.jpg"),
        require("./image/banner-3.jpg"),
      ],
      title:
        "Want A Robust And Feature-Rich Food Ordering App For Your Restaurants?",
      content:
        "Technology and Food Industry is a perfect match. If you have any plans for making a food delivery application for your business, it’s surely the right time. These food ordering applications create a gigantic opportunity for startups and established restaurant businesses to enter new markets. Want to take advantage of technology for taking your restaurant business to a whole new level?",
    },
    {
      section: "features",
      noContact: true,
      title:
        "What’s Special About Online Food Delivery App Solutions Developed At ProApp?",
      type: "border",
      items: [
        {
          title: "A Fully Customizable Solution",
          icon: "important_devices",
          description:
            "Our solution is completely branded and customizable with advanced features, integrations, and enhancements.",
        },
        {
          title: "Highly Scalable",
          icon: "storage",
          description:
            "The solution is built using emerging technologies and is fully tested to handle the high volume of food orders.",
        },
        {
          title: "Global Solution",
          icon: "public",
          description:
            "You can launch our solution globally as it comes with multi-language and currency support.",
        },
        {
          title: "Dedicated Support",
          icon: "contact_phone",
          description:
            "We guarantee that you will get maximum ROI with full support & maintenance with our Food Delivery service app.",
        },
      ],
    },
    {
      section: "features",
      noContact: true,
      title: "Why ProApp?",
      subtitle: "For Next generation on-demand app development services",
      items: [
        {
          title: "Support in less than 72 hrs",
          icon: "support_agent",
          description:
            "Chat & Voice Assistance Whatsapp, Skype, Livechat, or any necessary app for us to help you out.",
        },
        {
          title: "Amazon Web Services for Hosting",
          icon: "dns",
          description: "Only the best hosting services for you.",
        },
        {
          title: "Secure Data",
          icon: "lock",
          description:
            "Information always saves, we keep backups in case something happens to your information.",
        },
        {
          title: "99.999% Uptime",
          icon: "assignment_turned_in",
          description: "Your business must be running all the time.",
        },
        {
          title: "Constant Innovation",
          icon: "update",
          description: "Weekly Updates to keep our technology at the top.",
        },
        {
          title: "Project Advice",
          icon: "forum",
          description:
            "You want to know more about how your business will get up and running? Reach our Experts.",
        },
      ],
    },
    { section: "discuss" },
    require("../faq.js").default,
  ],
  _i18n: {
    "zh-Hant": {
      menuTitle: "餐飲外送App",
      //subtitle: "餐廳和餐飲外送服務的解決方案",
    },
  },
};
