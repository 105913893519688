export default {
  id: "mobile-app-development",
  menuTitle: "Mobile App Development",
  titles: [
    {
      size: "md",
      text: "A Mobile App Development Company Trusted By Industry Leaders",
      bold: true,
    },
    {
      text:
        "Being one of the best mobile app development Companies in the industry, we know what it takes to convert your vision into reality.",
    },
  ],
  backgroundImage: require("./image/background.png"),
  backgroundAlpha: 0.7,
  sections: [],
  _i18n: {
    "zh-Hant": {
      menuTitle: "手機應用開發",
    },
  },
};
