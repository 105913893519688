import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { dangerColor, title } from "../../assets/jss/material-kit-react.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import Button from "../CustomButtons/Button.js";
import CustomDropdown from "../CustomDropdown/CustomDropdown.js";
import CustomInput from "../CustomInput/CustomInput.js";

export default function ContactForm({ onSubmitSuccessed }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const inputs = [
    {
      title: t("Your Name"),
      required: true,
    },
    {
      title: t("Company Name"),
      required: true,
    },
    {
      title: t("Email Address"),
      required: true,
      email: true,
    },
    {
      title: t("Budget Range"),
      options: [
        t("Below {{0}}", ["HK$50,000"]),
        "HK$50,000 - HK$100,000",
        "HK$100,000 - HK$200,000",
        "HK$200,000 - HK$300,000",
        "HK$300,000 - HK$500,000",
        t("{{0}} and above", ["HK$500,000"]),
      ],
      required: true,
    },
    {
      title: t("Your Message"),
      required: true,
      textarea: true,
    },
  ];

  const initialValues = {};
  inputs.forEach(({ title }) => {
    if (title) initialValues[title] = "";
  });

  return (
    <Card>
      <Formik
        key={i18n.language}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          inputs.forEach(({ title, required, email }) => {
            if (!title) return;
            if (!values[title] && required) {
              errors[title] = "Required";
            } else if (
              email &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[title])
            )
              errors[title] = "Invalid email address";
          });

          console.log(errors);

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let msg = "";

          inputs.forEach(({ header, title }) => {
            if (header) msg += `<br /><b>${header}</b><br /><br />`;
            if (title) msg += `${title}: ${values[title]}<br />`;
          });

          const http = new XMLHttpRequest();
          http.open(
            "POST",
            "https://us-central1-proapp-5b1a8.cloudfunctions.net/sendMail",
            true
          );
          http.setRequestHeader("Content-Type", "application/json");
          http.onreadystatechange = function () {
            if (http.readyState === 4 && http.status === 200) {
              resetForm(initialValues);
              setSubmitting(false);
              onSubmitSuccessed();
            }
          };
          http.send(JSON.stringify({ msg }));
        }}
      >
        {({
          values,
          errors,
          touched,
          setValues,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <CardHeader color="danger" className={classes.cardHeader}>
              <h4 className={classes.formTitle}>{t("Contact Us")}</h4>
            </CardHeader>
            <CardBody>
              {inputs.map(({ title, email, textarea, options }, i) =>
                options ? (
                  <CustomDropdown
                    key={`${i}`}
                    hoverColor="danger"
                    buttonText={values[title] || title}
                    dropdownHeader={title}
                    buttonProps={{
                      className: `${classes.navLink} ${classes.dropdown}`,
                      color: "transparent",
                      style: {
                        color:
                          errors[title] && touched[title]
                            ? dangerColor
                            : undefined,
                      },
                    }}
                    caret={false}
                    dropdownList={options}
                    onClick={(v) => setValues({ ...values, [title]: v }, true)}
                  />
                ) : (
                  <CustomInput
                    key={`${i}`}
                    error={errors[title] && touched[title]}
                    labelText={title}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: email ? "email" : "text",
                      multiline: textarea,
                      rows: textarea ? 5 : undefined,
                      name: title,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values[title],
                    }}
                  />
                )
              )}
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <Button
                color="danger"
                disabled={isSubmitting}
                block
                type="submit"
              >
                {isSubmitting ? (
                  <ClipLoader color="white" loading size={20} />
                ) : (
                  t("Send Message")
                )}
              </Button>
            </CardFooter>
          </form>
        )}
      </Formik>
    </Card>
  );
}

const useStyles = makeStyles({
  formTitle: {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    color: "#FFF",
  },
  form: {
    margin: "0",
  },
  cardHeader: {
    margin: "0 15px",
    marginTop: "-20px",
    textAlign: "center",
    border: 0,
    padding: "1rem 15px",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)",
    },
  },
  dropdown: {
    top: 10,
    color: "#AAAAAA",
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.42857,
    letterSpacing: "unset",
    padding: "6px 0 7px",
    margin: "21px 0 17px 0",
    width: "100%",
    justifyContent: "left",
    textTransform: "none",
    "&:before": {
      left: 0,
      right: 0,
      bottom: 0,
      content: '"\\00a0"',
      position: "absolute",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      pointerEvents: "none",
      borderBottomColor: "#D2D2D2 !important",
      borderBottomWidth: "1px !important",
    },
  },
});

ContactForm.propTypes = {
  onSubmitSuccessed: PropTypes.func,
};
