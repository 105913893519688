import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

export default function SectionCards() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={7}>
            <div className={classes.imageContainer}>
              <img
                src={require("./cards.png")}
                alt="..."
                className={classes.image}
              />
            </div>
          </GridItem>
          <GridItem style={{ marginLeft: "auto" }} xs={12} sm={7} md={5}>
            <div style={{ textAlign: "center" }}>
              <h3 className={classes.title}>{t("10 Years Of Experience")}</h3>
              <h6 style={{ color: "rgba(255, 255, 255, 0.76)" }}>
                {t("Professional Team")}
              </h6>
              <h5 style={{ color: "rgba(255, 255, 255, 0.76)" }}>
                {t(
                  "We have been worked on most of the common Apps such as E-commerce, Food Delivery, Live Chat and Social Networking etc and we find that they have a lot of functions in common, reusing them do help you launch your new businesses more quickly and at a fraction of the cost and energy. Let's boost your sales with these reliable，well tested and market-proven functions."
                )}
              </h5>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "70px 0",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
    backgroundColor: "#343434",
  },
  container,
  imageContainer: {
    maxWidth: 1040,
    marginTop: -250,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: -120,
      marginLeft: -800,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: -140,
      marginLeft: 0,
      height: 440,
    },
  },
  image: {
    maxWidth: 1400,
    width: "auto",
    position: "absolute",
    right: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 800,
    },
  },
  title: {
    ...title,
    color: "#fff",
  },
}));
