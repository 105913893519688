import { useParams } from "react-router-dom";
import React from "react";
import useSolutions from "../../data/useSolutions";
import DynamicPage from "../DynamicPage/DynamicPage";

export default function SolutionPage() {
  const { solutionId } = useParams();

  const solutions = useSolutions();

  const {
    backgroundImage,
    backgroundAlpha,
    titles,
    iphoneImage,
    macbookImage,
    videoId,
    sections,
  } = solutions.find(({ id }) => id === solutionId);

  return (
    <DynamicPage
      backgroundImage={backgroundImage}
      backgroundAlpha={backgroundAlpha}
      titles={titles}
      iphoneImage={iphoneImage}
      macbookImage={macbookImage}
      videoId={videoId}
      sections={sections}
    />
  );
}
