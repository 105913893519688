import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import GetStartedButton from "../../../../components/GetStartedButton/GetStartedButton";
import FeatureItem from "./FeatureItem";

export default function SectionFeatures({
  title,
  subtitle,
  items,
  itemType,
  type,
  noContact,
}) {
  const classes = useStyles();

  let borderClasses;
  let sm;
  if (items.length > 6) {
    borderClasses = [
      classes.topLeft,
      classes.top,
      classes.top,
      classes.topRight,
      classes.bottomLeft,
      classes.bottom,
      classes.bottom,
      classes.bottomRight,
    ];
    sm = 3;
  } else if (items.length > 4) {
    borderClasses = [
      classes.topLeft,
      classes.top,
      classes.topRight,
      classes.bottomLeft,
      classes.bottom,
      classes.bottomRight,
    ];
    sm = 4;
  } else {
    borderClasses = [
      classes.topLeft,
      classes.topRight,
      classes.bottomLeft,
      classes.bottomRight,
    ];
    sm = 6;
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          {!!title && (
            <GridItem>
              <h2 className={classes.title}>{title}</h2>
            </GridItem>
          )}
          {!!subtitle && (
            <GridItem>
              <h5 className={classes.subtitle}>{subtitle}</h5>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer className={classes.content}>
          {items.map(({ title, description, icon }, i) => (
            <GridItem
              key={`item-${i}`}
              className={`${type === "border" ? classes.feature : ""} ${
                borderClasses[i]
              }`}
              sm={sm}
            >
              <FeatureItem
                type={itemType}
                title={title}
                icon={icon}
                description={description}
              />
            </GridItem>
          ))}
        </GridContainer>
        {!noContact && (
          <GridContainer justify="center">
            <GetStartedButton size="lg" />
          </GridContainer>
        )}
      </div>
    </div>
  );
}

SectionFeatures.propTypes = {
  HeaderComponent: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.array,
  type: PropTypes.string,
  itemType: PropTypes.string,
  noContact: PropTypes.bool,
};

const useStyles = makeStyles({
  section: {
    padding: "70px 0",
  },
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  feature: {
    border: "1px solid hsla(0, 3%, 33%, .1)",
  },
  topLeft: {
    borderLeft: "none",
    borderTop: "none",
  },
  top: {
    borderTop: "none",
  },
  topRight: {
    borderRight: "none",
    borderTop: "none",
  },
  bottomLeft: {
    borderLeft: "none",
    borderBottom: "none",
  },
  bottom: {
    borderBottom: "none",
  },
  bottomRight: {
    borderRight: "none",
    borderBottom: "none",
  },
  info: {
    maxWidth: 360,
    margin: "0 auto",
    padding: "70px 0 30px",
  },
  title: {
    ...title,
    color: "#000",
    whiteSpace: "pre-line",
  },
  subtitle: {
    color: "#000",
    whiteSpace: "pre-line",
  },
  textCenter: {
    textAlign: "center",
  },
  content: {
    padding: "80px 0",
  },
});
