import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

export default function SectionFAQ({ items }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          <GridItem>
            <h2 className={classes.title}>{t("FAQ")}</h2>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {[
            { start: 0, end: Math.ceil(items.length / 2) },
            { start: Math.ceil(items.length / 2), end: items.length },
          ].map(({ start, end }, i) => (
            <GridItem key={`item-${i}`} md={6}>
              {items.slice(start, end).map(({ title, description }, j) => (
                <Accordion
                  key={`accordion-${i}-${j}`}
                  expanded={expanded === `accordion-${i}-${j}`}
                  onChange={handleChange(`accordion-${i}-${j}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <h4 className={classes.itemTitle}>{title}</h4>
                  </AccordionSummary>
                  <AccordionDetails>{description}</AccordionDetails>
                </Accordion>
              ))}
            </GridItem>
          ))}
        </GridContainer>
        <div className={classes.space50} />
      </div>
    </div>
  );
}

SectionFAQ.propTypes = {
  items: PropTypes.array,
};

const useStyles = makeStyles({
  section: {},
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  title: {
    ...title,
    color: "#000",
  },
  description: {
    color: "#000",
  },
  itemTitle: {
    fontSize: 15,
    marginTop: 0,
    fontWeight: "bolder",
    marginBottom: 0,
  },
  textCenter: {
    textAlign: "center",
  },
});
