import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import imagesStyles from "../../../../assets/jss/material-kit-react/imagesStyles.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

export default function SectionClients() {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter}>
          <GridItem>
            <h2 className={classes.title}>{t("Our Clients")}</h2>
            <h5 className={classes.description}>
              {t(
                "Through The Years We Have Partnered With Great Companies And Entrepreneurs All Over The World"
              )}
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={3} className={classes.imageContainer}>
            <img
              src={require("./gammon.png")}
              alt="..."
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} className={classes.imageContainer}>
            <img
              src={require("./oceanpark.png")}
              alt="..."
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} className={classes.imageContainer}>
            <img
              src={require("./lanecrawford.png")}
              alt="..."
              className={classes.image}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} className={classes.imageContainer}>
            <img
              src={require("./bps.png")}
              alt="..."
              className={classes.image}
            />
          </GridItem>
        </GridContainer>

        <div className={classes.space50} />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  section: {},
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  title: {
    ...title,
    color: "#000",
  },
  description: {
    color: "#000",
  },
  textCenter: {
    textAlign: "center",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    padding: 30,
  },
  ...imagesStyles,
});
