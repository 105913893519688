import React from "react";
import useHome from "../../data/useHome";
import DynamicPage from "../DynamicPage/DynamicPage";

export default function HomePage() {
  const { backgroundAlpha, titles, sections } = useHome();

  return (
    <DynamicPage
      backgroundAlpha={backgroundAlpha}
      titles={titles}
      sections={sections}
    />
  );
}
