import { makeStyles } from "@material-ui/core/styles";
import { jarallax } from "jarallax";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { container, title } from "../../../../assets/jss/material-kit-react.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GetStartedButton from "../../../../components/GetStartedButton/GetStartedButton";
import GridItem from "../../../../components/Grid/GridItem.js";
import Jarallax from "../../../../components/Jarallax/Jarallax";

export default function SectionDiscuss({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    jarallax(document.querySelectorAll(`.${classes.jarallax}`), {
      speed: 0.2,
    });
    return () => {
      jarallax(document.querySelectorAll(`.${classes.jarallax}`), "destroy");
    };
  }, []);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <Jarallax
              className={classes.jarallax}
              image={require("./discuss.jpg")}
              type="scale-opacity"
            >
              <div className={classes.content}>
                <h2 className={classes.title}>
                  {title || t("Have a custom App Idea?")}
                </h2>
                <br />
                <GetStartedButton
                  title={title ? undefined : "Let's Discuss"}
                  size="lg"
                />
              </div>
            </Jarallax>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

SectionDiscuss.propTypes = {
  title: PropTypes.string,
};

const useStyles = makeStyles({
  section: {
    padding: "70px 0",
  },
  container,
  jarallax: {
    // remove borderRadius for Jarallax performance
    // borderRadius: 6,
    // overflow: "hidden",
    textAlign: "center",
    boxShadow:
      "0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)",
  },
  content: {
    position: "relative",
    padding: "30px 0",
    background: "rgba(0, 0, 0, 0.5)",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  title: {
    ...title,
    color: "#fff",
  },
});
