import DoubleArrow from "@material-ui/icons/DoubleArrow";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../CustomButtons/Button.js";
import ContactDialog from "../ContactDialog/ContactDialog.js";
import ContactThankYouDialog from "../ContactThankYouDialog/ContactThankYouDialog.js";

export default function GetStartedButton({
  title,
  size,
  round,
  color = "danger",
  noIcon,
}) {
  const [
    isOpenContactThankYouDialog,
    setIsOpenContactThankYouDialog,
  ] = useState(false);

  const { t } = useTranslation();

  const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

  return (
    <>
      <Button
        color={color}
        size={size}
        round={round}
        onClick={() => setIsOpenContactDialog(true)}
      >
        {title || t("Get Started")}
        {!noIcon && <DoubleArrow />}
      </Button>
      <ContactDialog
        open={isOpenContactDialog}
        onClose={() => setIsOpenContactDialog(false)}
        onSubmitSuccessed={() => setIsOpenContactThankYouDialog(true)}
      />
      <ContactThankYouDialog
        open={isOpenContactThankYouDialog}
        onClose={() => setIsOpenContactThankYouDialog(false)}
      />
    </>
  );
}

GetStartedButton.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  round: PropTypes.bool,
  noIcon: PropTypes.bool,
};
