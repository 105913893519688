export default {
  id: "on-demand-home-service-app-development",
  menuTitle: "On-Demand Home Services App",
  titles: [
    { size: "lg", text: "Launch On-Demand Home Service App", bold: true },
    {
      text:
        "Build a customized service app from scratch as per your business model.",
    },
  ],
  backgroundImage: require("./image/background.jpg"),
  backgroundAlpha: 0.45,
  videoId: "xSMc_MUqXVc",
  sections: [
    {
      section: "images",
      title: "On-Demand Home Service App Solution",
      subtitle: "DRIVE ROI WITH PROAPP",
      other: "We didn’t mention your industry?",
      items: [
        {
          title: "Home\nImprovement",
          image: require("./image/home-improvement.jpg"),
        },
        {
          title: "Cleaning",
          image: require("./image/cleaning.jpg"),
        },
        {
          title: "Handyman",
          image: require("./image/handyman.jpg"),
        },
        {
          title: "Plumbing",
          image: require("./image/plumbing.jpg"),
        },
        {
          title: "Carpenter",
          image: require("./image/carpenter.jpg"),
        },
        {
          title: "Electrical",
          image: require("./image/electrical.jpg"),
        },
        {
          title: "Beauty",
          image: require("./image/beauty.jpg"),
        },
        {
          title: "Salon",
          image: require("./image/salon.jpg"),
        },
        {
          title: "Pet Sitting",
          image: require("./image/pet-sitting.jpg"),
        },
        {
          title: "Event Planner",
          image: require("./image/event-planner.jpg"),
        },
        {
          title: "Child Care",
          image: require("./image/child-care.jpg"),
        },
        {
          title: "Baby Sitter",
          image: require("./image/baby-sitter.jpg"),
        },
        {
          title: "Meal Delivery",
          image: require("./image/meal-delivery.jpg"),
        },
        {
          title: "Private Nursing",
          image: require("./image/private-nursing.jpg"),
        },
        {
          title: "Pest Control",
          image: require("./image/pest-control.jpg"),
        },
      ],
    },
    {
      section: "overview",
      headerTitle: "Home Service App Solution",
      headerSubtitle: "STREAMLINE YOUR BUSINESS WITH OUR TAILORED MADE",
      image: require("./image/customer.jpg"),
      animatedImages: [
        require("./image/customer-1.jpg"),
        require("./image/customer-2.jpg"),
      ],
      backgroundImage: require("./image/background-1.jpg"),
      backgroundAlpha: 0.4,
      title: "CUSTOMER APPLICATION",
      subtitle:
        "Give A Highly Interactive And Easy To Use Application Powered With An Advanced Booking System.",
      items: [
        "Simple & Secure Login",
        "Advance Booking System",
        "Easy Scheduling",
        "Real-Time Tracking",
        "Special Offers/Promo",
      ],
    },
    {
      section: "overview",
      image: require("./image/admin.jpg"),
      animatedImages: [
        require("./image/admin-1.jpg"),
        require("./image/admin-2.jpg"),
      ],
      macbook: true,
      title: "ADMIN DASHBOARD",
      subtitle:
        "Get A Powerful Admin Dashboard And Manage Your Business Easily.",
      items: [
        "Advanced Service Listing",
        "Manage Price & Discounts",
        "Manage Service Professional",
        "Manage Bookings & Scheduling",
      ],
    },
    {
      section: "overview",
      image: require("./image/provider.jpg"),
      animatedImages: [
        require("./image/provider-1.jpg"),
        require("./image/provider-2.jpg"),
      ],
      backgroundImage: require("./image/background-2.jpg"),
      title: "SERVICE PROFESSIONAL APP",
      subtitle: "Application Powered With High-End Features For Professionals.",
      items: [
        "Interactive UI/UX",
        "Quick Notification",
        "Easy Navigation",
        "Booking Management",
        "Services Management",
      ],
    },
    {
      section: "features",
      noContact: true,
      title:
        "What’s Special About On-Demand Home Service Solutions Developed At ProApp?",
      type: "border",
      items: [
        {
          title: "A Fully Customizable Solution",
          icon: "important_devices",
          description:
            "Our solution is completely branded and customizable with advanced features, integrations, and enhancements.",
        },
        {
          title: "Highly Scalable",
          icon: "storage",
          description:
            "The solution is built using emerging technologies and is fully tested to handle the high volume of orders.",
        },
        {
          title: "Global Solution",
          icon: "public",
          description:
            "You can launch our solution globally as it comes with multi-language and currency support.",
        },
        {
          title: "Dedicated Support",
          icon: "contact_phone",
          description:
            "We guarantee that you will get maximum ROI with full support & maintenance with our On-Demand Home service app.",
        },
      ],
    },
    {
      section: "features",
      noContact: true,
      title: "Why ProApp?",
      subtitle: "For Next generation on-demand app development services",
      items: [
        {
          title: "Support in less than 72 hrs",
          icon: "support_agent",
          description:
            "Chat & Voice Assistance Whatsapp, Skype, Livechat, or any necessary app for us to help you out.",
        },
        {
          title: "Amazon Web Services for Hosting",
          icon: "dns",
          description: "Only the best hosting services for you.",
        },
        {
          title: "Secure Data",
          icon: "lock",
          description:
            "Information always saves, we keep backups in case something happens to your information.",
        },
        {
          title: "99.999% Uptime",
          icon: "assignment_turned_in",
          description: "Your business must be running all the time.",
        },
        {
          title: "Constant Innovation",
          icon: "update",
          description: "Weekly Updates to keep our technology at the top.",
        },
        {
          title: "Project Advice",
          icon: "forum",
          description:
            "You want to know more about how your business will get up and running? Reach our Experts.",
        },
      ],
    },
    { section: "discuss" },
  ],
  _i18n: {
    "zh-Hant": {
      menuTitle: "預約服務App",
    },
  },
};
